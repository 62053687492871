.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: auto;
  color: white;
  background-color: rgb(46, 43, 43);
  display: flex;
  flex-direction: column;
}

.card div {
  font-family: Arial, sans-serif;
  font-size: 20px;
  flex-grow: 1;
}
.card span{
  font-size: 11px;
  flex-grow: 1;
}
.card span span{
  font-size: 9px;
  flex-grow: 1;
}
.card div br {
  margin-top: 5px;
}

.card div span {
  font-weight: bold;
  color: #333;
}
